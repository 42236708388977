import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class LocalStateService {
  private _showTabsMenu = false

  constructor() {}

  public getShowTabsMenu() {
    return this._showTabsMenu
  }

  public setShowTabsMenu() {
    // this is a latch
    this._showTabsMenu = true
  }
}
