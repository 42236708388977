import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { NavigationEnd, NavigationStart, Params, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { merge, Subject } from 'rxjs'
import { filter, take, takeUntil } from 'rxjs/operators'

import { PendoService } from '@zonar-ui/analytics'
import { I18nService } from '@zonar-ui/i18n'

import { APP_VERSION_STRING } from 'src/app/version'
import { environment } from 'src/environments/environment'
import { DataDogRumService } from './services/data-dog-rum/data-dog-rum.service'
import { currentLanguageCode$ } from './components/tree/services/language-dictionary-handling/language-dictionary-handling.service'
import { LANGUAGE_CODE_BY_LANGUAGE_ID } from './constants/internationalized-constants-en'
import { PermissionsService } from '@zonar-ui/auth'
import { AuthService } from '@auth0/auth0-angular'
import { Store } from '@ngxs/store'
import { AuthWiringService } from './services/auth-wiring/auth-wiring.service'
import { SetCompanyContext } from './app.state'
import { LocalStateService } from './services/local-state/local-state.service'

export let firstVisitUrl: string = null
export const resetFirstVisitUrl = () => (firstVisitUrl = null)

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading = true
  translationsNotLoaded = true
  SIDENAV_PARAMS = {
    expanded: true,
    footer: true,
    footerOffset: false,
    hideChangeCompanyButton: false,
    hideLogoutButton: false,
    lockExpansion: false,
    mobileOpened: true,
    useDefaultHelpItem: true,
    enableContentResizing: true,
  }
  sidenavFooter: {
    items: []
  }

  private destroy$: Subject<boolean> = new Subject<boolean>()
  private currentCompany: string

  hideSideNav: boolean | null = null

  constructor(
    @Inject(APP_VERSION_STRING) private appVersion: string,
    private permissionService: PermissionsService,
    private renderer: Renderer2,
    private router: Router,
    private datadogRumService: DataDogRumService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private pendoService: PendoService,
    private authService: AuthService,
    private store: Store,
    private authWiringService: AuthWiringService,
    private localStateService: LocalStateService
  ) {
    if (environment.region === 'EU') {
      this.renderer.addClass(document.body, 'zonar-conti-theme')
    } else {
      this.renderer.addClass(document.body, 'zonar-default-theme')
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        take(1)
      )
      .subscribe((event: NavigationStart) => {
        firstVisitUrl = event.url
      })

    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse())
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.translationsNotLoaded = false
          currentLanguageCode$.next(
            LANGUAGE_CODE_BY_LANGUAGE_ID[this.translateService.currentLang]
          )
        })
    }
  }

  ngOnInit() {
    console.log('ZONAR INC Ⓒ - ECT 2 ', `v${this.appVersion}`)
    console.log(`Environment: ${environment.environment}`)
    console.log('')

    // set the company context
    this.setCompanyContext()

    this.authWiringService.initialize()

    // initialize pendo after we get the permissions
    this.permissionService
      .getPermissions()
      .pipe(filter((permissions) => Boolean(permissions)))
      .subscribe(() => {
        this.pendoService.initialize()
      })
    this.getQueryParams()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }
  handleQueryParamChanges(queryParams: Params) {
    const hideSideNav = queryParams['hideSideNav']
    this.hideSideNav = hideSideNav
      ? hideSideNav === 'true'
      : this.localStateService.getShowTabsMenu()

    if (this.hideSideNav) {
      // latch this on -- internal route changes are not expected to retain "hideSideNav"
      this.localStateService.setShowTabsMenu()
    }
  }
  getQueryParams() {
    // Check query parameters for 'hideSideNav'
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.handleQueryParamChanges(this.router.parseUrl(this.router.url).queryParams)
      })
  }

  setCompanyContext() {
    // trigger the completion of the permissions logic so that PermissionsGuard can complete its observable
    this.permissionService
      .getCurrentCompanyContext()
      .pipe(filter((companyContext) => Boolean(companyContext)))
      .subscribe((companyContext) => {
        if (companyContext?.id) {
          this.store.dispatch(
            new SetCompanyContext({ id: companyContext.id, name: companyContext.name })
          )

          this.router.navigate(['/companies', companyContext.id])
          this.loading = false
        }
      })
  }

  /**
   * Dispatch the selected company to the store
   * @param incomingCompany Current Company Context
   */
  switchToSelectedCompany(incomingCompany: { title: string; value: string }) {
    if (
      !this.loading &&
      incomingCompany?.value &&
      incomingCompany.value !== this.currentCompany
    ) {
      const companyId = incomingCompany.value
      this.currentCompany = companyId

      this.store.dispatch(
        new SetCompanyContext({ id: incomingCompany.value, name: incomingCompany.title })
      )

      this.router.navigate(['/companies', companyId])
    }
  }
}
